import { TOutputBalanceArray } from "../../types";
import { TBankArray, TBankSummarizedArray } from "./types";

export function sumBankBalanceByCurrency(
  arrayToSort: TBankArray,
  currencyArray: string[]
) {
  let newArray: TBankSummarizedArray = [];

  newArray = arrayToSort.map((bank) => {
    let newBankAccountsArray: TOutputBalanceArray = [];
    currencyArray.forEach((item) => {
      let totalSum = 0;
      let corpSum = 0;
      let loanSum = 0;
      let bondSum = 0;
      let filteredTotalArray = bank.bankAcounts.filter((i) => item === i.symbol && i.type !== "corp" && i.type !== "bond" && i.type !== "loan");
      let filteredCorpArray = bank.bankAcounts.filter((i) => item === i.symbol && i.type === "corp");
      let filteredLoanArray = bank.bankAcounts.filter((i) => item === i.symbol && i.type === "loan");
      let filteredBondArray = bank.bankAcounts.filter((i) => item === i.symbol && i.type === "bond");

      if(filteredTotalArray.length) {
        totalSum = Math.ceil(filteredTotalArray.reduce((prev, curr) => {
            return prev + curr.amount;
        }, 0))
      }
      if(filteredCorpArray.length) {
        corpSum = Math.ceil(filteredCorpArray.reduce((prev, curr) => {
            return prev + curr.amount;
        }, 0))
      }
      if(filteredLoanArray.length) {
        loanSum = Math.ceil(filteredLoanArray.reduce((prev, curr) => {
            return prev + curr.amount;
        }, 0))
      }
      if(filteredBondArray.length) {
        bondSum = Math.ceil(filteredBondArray.reduce((prev, curr) => {
            return prev + curr.amount;
        }, 0))
      }
        newBankAccountsArray.push({ symbol: item, amount: totalSum, corpAmount: corpSum, loanAmount: loanSum, bondAmount: bondSum }); 
    });
    return {
      bankId: bank.bankId,
      bankName: bank.bankName,
      bankAcounts: newBankAccountsArray,
    };
  });
  return newArray;
}

export function returnTotalBankBalance(
  arrayToCalculate: TBankSummarizedArray,
  currencyArray: string[]
) {
  let newArray: TOutputBalanceArray = [];

  newArray = currencyArray.map((item) => {
    let totalAmountArray: number[] = [];
    let corpAmountArray: number[] = [];
    let loanAmountArray: number[] = [];
    let bondAmountArray: number[] = [];
    arrayToCalculate.forEach((bank) => {
      let findedCurrency = bank.bankAcounts.find((i) => item === i.symbol);

      if (findedCurrency) {
        if(findedCurrency.corpAmount ){
          if(Number(findedCurrency.corpAmount) > 0) {
            corpAmountArray.push(Number(findedCurrency.corpAmount))
          }
        } 
        if(findedCurrency.loanAmount ){
          if(Number(findedCurrency.loanAmount) > 0) {
            loanAmountArray.push(Number(findedCurrency.loanAmount))
          }
        } 
        if(findedCurrency.bondAmount ){
          if(Number(findedCurrency.bondAmount) > 0) {
            bondAmountArray.push(Number(findedCurrency.bondAmount))
          }
        } 

        if(Number(findedCurrency.amount) > 0) {
          totalAmountArray.push(Number(findedCurrency.amount))
        }
      }
    });

    let totalSum = totalAmountArray.reduce((prev, curr) => {
      return prev + curr;
    }, 0);
    let corpSum = corpAmountArray.reduce((prev, curr) => {
      return prev + curr;
    }, 0);
    let loanSum = loanAmountArray.reduce((prev, curr) => {
      return prev + curr;
    }, 0);
    let bondSum = bondAmountArray.reduce((prev, curr) => {
      return prev + curr;
    }, 0);
    return { symbol: item, amount: totalSum, corpAmount: corpSum, loanAmount: loanSum, bondAmount: bondSum }
  });

  return newArray;
}
