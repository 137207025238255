import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { fetchLoginStart, reset } from "../../api/auth";
import { fetchMenuStart } from "../../api/menu";
import { TwoFactorForm } from "../../components/2fa-form/TwoFactorForm";
import { Spinner } from "../../components/spinner/Spinner";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { AppPages } from "../../types";
import styles from "./TwoFactorPage.module.css";
// import { fetchPostLogsStart } from "../../api/user";

type TwoFactorPageProps = {};

export const TwoFactorPage: React.FC<TwoFactorPageProps> = () => {
  let error;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  let status = useAppSelector((state) => state.login.fetchStatus);
  const fetchResult = useAppSelector((state) => state.login.result);
  let LS = sessionStorage.getItem("user");
  const menuArray = useAppSelector((state) => state.menu.menuItems);

  const handleCode = (
    login: string,
    password: string,
    state: string,
    code: string,
    identityType: string
  ) => {
    dispatch(
      fetchLoginStart({
        login: login,
        password: password,
        state: state,
        code: code,
        identityType: identityType,
      })
    );
  };

  useEffect(() => {
    if (fetchResult?.accessToken) {
      dispatch(fetchMenuStart({ accessToken: fetchResult?.accessToken }));
    }
  }, [dispatch, fetchResult]);

  // useEffect(() => {
  //   if (fetchResult?.accessToken) {
  //     dispatch(fetchPostLogsStart({ accessToken: fetchResult?.accessToken }));
  //   }
  // }, [dispatch, fetchResult]);

  useEffect(() => {
    if (menuArray.length) {
      if (menuArray.length === 1) {
        navigate(menuArray[0].menuUrl);
        sessionStorage.removeItem("user");
      }

      if (menuArray.length > 1) {
        navigate(AppPages.MAIN);
        sessionStorage.removeItem("user");
      }
    }
  }, [navigate, menuArray, fetchResult]);


  if (status === "pending") {
    return <Spinner  width={100} height={100} marginTop="200px"></Spinner>;
  }

  if (status === "success") {
    if (fetchResult?.accessToken) {
      error = "";
      localStorage.setItem("accessToken", JSON.stringify(fetchResult?.accessToken));
      dispatch(reset());
    } else {
      if (fetchResult?.code === "MULTI_FACTOR_STATE_EXPIRED") {
        error = "Сессия истекла, перенаправление на страницу логина";
        sessionStorage.removeItem("user");
        setTimeout(() => {
          navigate(AppPages.LOGIN);
        }, 3000);
        dispatch(reset());
      } else {
        error = "Код недействителен";
      }
    }
  }

  return LS ? (
    <section className={styles.wrapper}>
      <div className={styles.formContainer}>
      <svg
          className={styles.logo}
            width="763"
            height="134"
            viewBox="0 0 763 134"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M541.742 117.42H516.64L552.644 65.6869L518.792 17.6592H543.894L566.128 50.438L588.218 17.6592H613.463L579.611 65.6869L615.615 117.42H590.37L566.128 81.0793L541.742 117.42Z"
              fill="#181a21"
            />
            <path
              d="M104.158 117.42H82.6562V17.6592H123.938C129.195 17.6592 134.02 18.1824 138.416 19.2274C142.907 20.2725 147.111 21.9817 151.029 24.3578C155.043 26.7326 158.148 30.1049 160.347 34.4762C162.641 38.8462 163.787 44.0715 163.787 50.1534C163.787 65.2599 156.477 75.1411 141.856 79.7957L171.814 117.42H147.016L119.925 82.5039H104.158V117.42ZM104.158 36.4715V64.6892H124.941C129.816 64.6892 133.829 63.5493 136.983 61.2694C140.231 58.8933 141.856 55.3312 141.856 50.5804C141.856 45.8295 140.28 42.1725 137.126 39.8913C133.972 37.6114 129.911 36.4715 124.941 36.4715H104.158Z"
              fill="#181a21"
            />
            <path
              d="M183.618 17.6592H254.402V36.4715H205.154V57.706H250.094V76.3759H205.154V98.608H255.263V117.42H183.618V17.6592Z"
              fill="#181a21"
            />
            <path
              d="M267.076 17.6592H337.86V36.4715H288.612V57.706H333.552V76.3759H288.612V98.608H338.721V117.42H267.076V17.6592Z"
              fill="#181a21"
            />
            <path
              d="M433.19 17.6592H503.974V36.4715H454.726V57.706H499.666V76.3759H454.726V98.608H504.835V117.42H433.19V17.6592Z"
              fill="#181a21"
            />
            <path
              d="M0 17.6592H70.8486V36.8984H21.5133V60.1295H66.6892V79.3688H21.5133V117.42H0V17.6592Z"
              fill="#181a21"
            />
            <path
              d="M421.374 100.281V117.42H353.166V106.816L383.53 70.0696L388.37 64.2592L392.918 58.885C393.797 57.8201 394.824 56.4153 395.997 54.6731C398.051 51.7673 399.078 48.9603 399.078 46.2481C399.078 42.9561 397.953 40.1966 395.704 37.9694C393.455 35.7422 390.179 34.6287 385.877 34.6287C381.574 34.6287 378.297 35.8872 375.755 38.4056C373.213 40.9227 371.696 44.4572 371.208 49.0077H350.525C350.72 39.0342 353.899 31.1429 360.059 25.3326C366.221 19.426 374.631 16.4727 385.289 16.4727C395.947 16.4727 404.555 19.3772 410.813 25.1876C417.169 30.9005 420.347 37.9206 420.347 46.2481C420.347 50.8962 418.978 55.3017 416.239 59.4661C414.187 62.6619 409.102 69.1971 400.984 79.0744L383.382 100.281H421.374Z"
              fill="url(#paint0_linear_227_2)"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M695.308 0C732.281 0 762.252 29.9712 762.252 66.9566C762.252 103.942 732.281 133.9 695.308 133.9C658.336 133.9 628.352 103.929 628.352 66.9566C628.352 29.9844 658.322 0 695.308 0ZM665.945 94.0743V79.782L678.361 67.3655H665.945V55.6101H690.117L705.717 40.0104H650.344V94.0611H665.945V94.0743ZM720.405 69.1095L718.345 67.0489L745.371 40.0236H723.312L669.247 94.0743H691.306L707.302 78.0789L723.298 94.0743H745.357L721.436 70.1398L720.405 69.1095Z"
              fill="url(#paint1_linear_227_2)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_227_2"
                x1="440.681"
                y1="17.7556"
                x2="340.818"
                y2="117.977"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#00BEB4" />
                <stop offset="1" stop-color="#0166A5" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_227_2"
                x1="762.079"
                y1="4.25275e-06"
                x2="628.313"
                y2="133.63"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#00BEB4" />
                <stop offset="1" stop-color="#005BA3" />
              </linearGradient>
            </defs>
          </svg>
        <h2 className={styles.title}>Enter 2FA code</h2>
        <TwoFactorForm handleClick={handleCode}></TwoFactorForm>
        {error ? <p className={styles.error}>{error}</p> : null}
      </div>
    </section>
  ) : (
    <Navigate to={AppPages.LOGIN} />
  );
};
