import { useState } from "react";
import { TResultGetCryptoCurrenciesQuotesArray } from "../../../../functions/cryptocurrencies/cryptocurrenciesTypes";
import { TQuotesFormatted } from "../../../api/cryptoQuotes/types";
import { TRatesFormatted } from "../../../api/rates/types";
import {
  findCurrencyInfo,
  findFiatCurInfo,
  findQuote,
  findRate,
  findValueOfCoin,
  findValueOfCoinWithoutTrunc,
  returnColor,
  returnColorOfChanges,
} from "../../../helpers";
import { totalTableСolumns } from "../../../settings";
import { TOutputBalanceArray } from "../../../types";
import styles from "./TableTotal.module.css";
import { FormButton } from "../../buttons/form-button/FormButton";
import { CSVLink } from "react-csv";
import generatePDF, { Margin } from "react-to-pdf";

type TableTotalProps = {
  sortedData: TOutputBalanceArray[];
  sortedAllSymbols: string[];
  totalBalances: TOutputBalanceArray;
  quotesFormatted: TQuotesFormatted;
  sortedDataWithUSD: TOutputBalanceArray[];
  ratesFormatted: TRatesFormatted;
  totalBalancesInUSD: TOutputBalanceArray;
  quotes: TResultGetCryptoCurrenciesQuotesArray;
  selectedFilter: string;
  tableName: string;
  date: string;
  dateTime: { date: string; time: string };
  targetRef: any;
  errorAssets: string[];
  token: string | null;
};

export const TableTotal: React.FC<TableTotalProps> = ({
  sortedData,
  sortedAllSymbols,
  totalBalances,
  quotesFormatted,
  totalBalancesInUSD,
  ratesFormatted,
  quotes,
  selectedFilter,
  sortedDataWithUSD,
  tableName,
  date,
  dateTime,
  targetRef,
  errorAssets,
  token,
}) => {
  const [modal, setModal] = useState<string>();
  const windowScreenWidth = window.screen.width;
  //form csv
  let csvData: any = [];
  csvData.push([tableName]);
  csvData.push([date]);
  let ths = ["Активы", "Курс"];

  totalTableСolumns.forEach((item) => ths.push(item));
  ths.push("Итого активы");
  ths.push("Итого USD");
  csvData.push(ths);
  if (selectedFilter === "all") {
    let tr = ["Итого USD"];
    sortedDataWithUSD.forEach((item) =>
      tr.push(
        item.reduce((prev, curr) => prev + Number(curr?.amount), 0).toFixed(6)
      )
    );
    tr.push("");
    tr.push(
      sortedDataWithUSD
        .map((group) => {
          return group.reduce((prev, curr) => prev + Number(curr?.amount), 0);
        })
        .reduce((prev, curr) => prev + curr, 0)
        .toFixed(6)
    );
    csvData.push(tr);
  }

  sortedAllSymbols.forEach((symbol) => {
    let tr = [];
    tr.push(symbol);
    tr.push(
      findQuote(symbol, quotesFormatted)
        ? findQuote(symbol, quotesFormatted)
        : findRate(symbol, ratesFormatted)
    );

    sortedData.forEach((item) => {
      if (findValueOfCoinWithoutTrunc(symbol, item).length === 1) {
        tr.push(
          findValueOfCoinWithoutTrunc(symbol, item)[0] === "-"
            ? 0
            : findValueOfCoinWithoutTrunc(symbol, item)[0]
        );
      } else {
        tr.push(findValueOfCoinWithoutTrunc(symbol, item).join(" "));
      }
    });
    tr.push(
      findValueOfCoinWithoutTrunc(symbol, totalBalances)[0] === "-"
        ? 0
        : findValueOfCoinWithoutTrunc(symbol, totalBalances)[0]
    );
    tr.push(
      findValueOfCoinWithoutTrunc(symbol, totalBalancesInUSD)[0] === "-"
        ? 0
        : findValueOfCoinWithoutTrunc(symbol, totalBalancesInUSD)[0]
    );
    csvData.push(tr);
  });

  let formattedTableName = (date + "_" + tableName)
    .replaceAll(".", "_")
    .replaceAll(",", "")
    .replaceAll(" ", "_");
  let formattedR2TableName = (dateTime.date + "_" + tableName)
    .replaceAll(".", "_")
    .replaceAll(",", "")
    .replaceAll(" ", "_");

  async function onCSVClick(csvData: any, formattedR2TableName: string) {
    let login = localStorage.getItem("login");
    if (login === "auto-bot@free2ex.com") {
      await fetch("/api/treasury/save", {
        method: "POST",
        body: JSON.stringify({
          csvData: csvData,
          name: formattedR2TableName,
          tableName: tableName
        }),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
  }

  return sortedData && sortedData.length ? (
    <>
      <div className={styles.flex}>
        <p className={styles.date}>{date}</p>
        <div id="exports" className={styles.exports}>
          <FormButton
            role="button"
            children={
              <span className={styles.exportSpan}>
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.2806 7.71938L15.0306 2.46938C14.9609 2.39975 14.8782 2.34454 14.7871 2.3069C14.6961 2.26926 14.5985 2.24992 14.5 2.25H5.5C5.10218 2.25 4.72064 2.40804 4.43934 2.68934C4.15804 2.97064 4 3.35218 4 3.75V20.25C4 20.6478 4.15804 21.0294 4.43934 21.3107C4.72064 21.592 5.10218 21.75 5.5 21.75H19C19.3978 21.75 19.7794 21.592 20.0607 21.3107C20.342 21.0294 20.5 20.6478 20.5 20.25V8.25C20.5001 8.15148 20.4807 8.05391 20.4431 7.96286C20.4055 7.87182 20.3503 7.78908 20.2806 7.71938ZM15.0306 15.5306L12.7806 17.7806C12.711 17.8504 12.6283 17.9057 12.5372 17.9434C12.4462 17.9812 12.3486 18.0006 12.25 18.0006C12.1514 18.0006 12.0538 17.9812 11.9628 17.9434C11.8717 17.9057 11.789 17.8504 11.7194 17.7806L9.46937 15.5306C9.32864 15.3899 9.24958 15.199 9.24958 15C9.24958 14.801 9.32864 14.6101 9.46937 14.4694C9.61011 14.3286 9.80098 14.2496 10 14.2496C10.199 14.2496 10.3899 14.3286 10.5306 14.4694L11.5 15.4397V11.25C11.5 11.0511 11.579 10.8603 11.7197 10.7197C11.8603 10.579 12.0511 10.5 12.25 10.5C12.4489 10.5 12.6397 10.579 12.7803 10.7197C12.921 10.8603 13 11.0511 13 11.25V15.4397L13.9694 14.4694C14.1101 14.3286 14.301 14.2496 14.5 14.2496C14.699 14.2496 14.8899 14.3286 15.0306 14.4694C15.1714 14.6101 15.2504 14.801 15.2504 15C15.2504 15.199 15.1714 15.3899 15.0306 15.5306ZM14.5 8.25V4.125L18.625 8.25H14.5Z"
                    fill="white"
                  />
                </svg>
                PDF
              </span>
            }
            className={styles.button}
            onClick={() => {
              let exports = document.getElementById("exports")!;
              exports.style.display = "none";
              if (windowScreenWidth < 767) {
                let table = document.getElementById("table")!;
                table.style.tableLayout = "fixed";
                table.style.position = "static";
                let tableBox = document.getElementById("tableBox")!;
                tableBox.style.width = "100%";
                tableBox.style.height = "100%";
                tableBox.style.overflowX = "none";
                let ths = document.querySelectorAll("th");
                [...ths].forEach((th) => (th.style.position = "static"));

                let tds = document.querySelectorAll("td");
                [...tds].forEach(
                  (tdTotal) => (tdTotal.style.position = "static")
                );

                generatePDF(targetRef, {
                  filename: `${formattedTableName}.pdf`,
                  page: {
                    margin: Margin.SMALL,
                    format: "a4",
                  },
                });
                exports.style.display = "flex";
                table.style.tableLayout = "auto";
                table.style.position = "relative";
                tableBox.style.height = "100vh";
                tableBox.style.overflowX = "scroll";
                [...ths].forEach((th) => (th.style.position = "sticky"));
                [...tds].forEach((td) => {
                  if (
                    td.className.includes("tdTotal_") ||
                    td.className.includes("tdCurrencies_")
                  ) {
                    td.style.position = "sticky";
                  }
                  if (
                    td.className.includes("tdTotalUSD_") &&
                    td.innerText === "Итого, USD"
                  ) {
                    td.style.position = "sticky";
                  }
                });
              } else {
                generatePDF(targetRef, {
                  filename: `${formattedTableName}.pdf`,
                  page: {
                    margin: Margin.SMALL,
                  },
                });
                exports.style.display = "flex";
              }
            }}
          ></FormButton>

          <CSVLink
            id="csv_btn"
            filename={`${formattedTableName}.csv`}
            className={styles.csv}
            data={csvData}
            onClick={() => onCSVClick(csvData, formattedR2TableName)}
          >
            <span className={styles.exportSpan}>
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.2806 7.71938L15.0306 2.46938C14.9609 2.39975 14.8782 2.34454 14.7871 2.3069C14.6961 2.26926 14.5985 2.24992 14.5 2.25H5.5C5.10218 2.25 4.72064 2.40804 4.43934 2.68934C4.15804 2.97064 4 3.35218 4 3.75V20.25C4 20.6478 4.15804 21.0294 4.43934 21.3107C4.72064 21.592 5.10218 21.75 5.5 21.75H19C19.3978 21.75 19.7794 21.592 20.0607 21.3107C20.342 21.0294 20.5 20.6478 20.5 20.25V8.25C20.5001 8.15148 20.4807 8.05391 20.4431 7.96286C20.4055 7.87182 20.3503 7.78908 20.2806 7.71938ZM15.0306 15.5306L12.7806 17.7806C12.711 17.8504 12.6283 17.9057 12.5372 17.9434C12.4462 17.9812 12.3486 18.0006 12.25 18.0006C12.1514 18.0006 12.0538 17.9812 11.9628 17.9434C11.8717 17.9057 11.789 17.8504 11.7194 17.7806L9.46937 15.5306C9.32864 15.3899 9.24958 15.199 9.24958 15C9.24958 14.801 9.32864 14.6101 9.46937 14.4694C9.61011 14.3286 9.80098 14.2496 10 14.2496C10.199 14.2496 10.3899 14.3286 10.5306 14.4694L11.5 15.4397V11.25C11.5 11.0511 11.579 10.8603 11.7197 10.7197C11.8603 10.579 12.0511 10.5 12.25 10.5C12.4489 10.5 12.6397 10.579 12.7803 10.7197C12.921 10.8603 13 11.0511 13 11.25V15.4397L13.9694 14.4694C14.1101 14.3286 14.301 14.2496 14.5 14.2496C14.699 14.2496 14.8899 14.3286 15.0306 14.4694C15.1714 14.6101 15.2504 14.801 15.2504 15C15.2504 15.199 15.1714 15.3899 15.0306 15.5306ZM14.5 8.25V4.125L18.625 8.25H14.5Z"
                  fill="white"
                />
              </svg>
              CSV{" "}
            </span>
          </CSVLink>
        </div>
      </div>
      <div className={styles.tableBox} id="tableBox">
        <table className={styles.table} id="table">
          <tr>
            <th className={styles.stickyTh}>Активы</th>
            {totalTableСolumns.map((item) => {
              return <th className={styles.stickyTh}>{item}</th>;
            })}
            <th className={styles.stickyTh}>Итого, активы</th>
            <th className={styles.stickyTh}>Итого, USD</th>
          </tr>
          {/* {selectedFilter === "all" ? ( */}
          <tr>
            <td className={styles.tdTotalUSD}>Итого, USD</td>
            {sortedDataWithUSD.map((item) => {
              return (
                <td className={styles.tdTotal}>
                  {Math.trunc(
                    item.reduce(
                      (prev, curr) =>
                        prev +
                        ((curr.amount !== "not_found" && !isNaN(+curr.amount))
                          ? Number(curr?.amount)
                          : 0),
                      0
                    )
                  )
                    .toString()
                    .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 ")}
                </td>
              );
            })}
            <td className={styles.tdTotal}></td>
            <td className={styles.tdTotal}>
              {Math.trunc(
                sortedDataWithUSD
                  .map((group) => {
                    return group.reduce(
                      (prev, curr) =>
                        prev +
                        ((curr.amount !== "not_found" && !isNaN(+curr.amount))
                          ? Number(curr?.amount)
                          : 0),
                      0
                    );
                  })
                  .reduce((prev, curr) => prev + curr, 0)
              )
                .toString()
                .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 ")}
            </td>
          </tr>
          {/* ) : null} */}
          {sortedAllSymbols.map((symbol) => (
            <tr>
              <td
                className={styles.tdCurrencies}
                role="button"
                onMouseOver={(e) => {
                  e.preventDefault();
                  setModal(symbol);
                }}
                onMouseOut={(e) => {
                  e.preventDefault();
                  setModal("");
                }}
              >
                <p
                  className={styles.symbol}
                  style={{ color: returnColor(symbol, quotesFormatted) }}
                >
                  {symbol}
                </p>
                <p>
                  {findQuote(symbol, quotesFormatted)
                    ? findQuote(symbol, quotesFormatted)
                    : findRate(symbol, ratesFormatted)}
                </p>
                {findQuote(symbol, quotesFormatted) ? (
                  <div
                    className={
                      modal === symbol
                        ? styles.currencyInfo
                        : styles.currencyInfoHidden
                    }
                    id={symbol}
                  >
                    <div>
                      {findCurrencyInfo(symbol, quotes)?.map((item) => (
                        <p className={styles.currencyInfoTitle}>
                          {item[0]}:{" "}
                          <span
                            className={styles.currencyInfoText}
                            style={{ color: returnColorOfChanges(item) }}
                          >
                            {item[1]}
                          </span>
                        </p>
                      ))}
                    </div>
                  </div>
                ) : findRate(symbol, ratesFormatted) ? (
                  <div
                    className={
                      modal === symbol
                        ? styles.currencyInfo
                        : styles.currencyInfoHidden
                    }
                    id={symbol}
                  >
                    <div>
                      {findFiatCurInfo(symbol, ratesFormatted)?.map((item) => (
                        <p className={styles.currencyInfoTitle}>
                          {item[0]}:{" "}
                          <span className={styles.currencyInfoText}>
                            {item[1]}
                          </span>
                        </p>
                      ))}
                    </div>
                  </div>
                ) : null}
              </td>
              {sortedData.map((item) => (
                <td className={styles.td}>{findValueOfCoin(symbol, item)}</td>
              ))}
              <td className={styles.tdTotal}>
                {findValueOfCoin(symbol, totalBalances)}
              </td>
              <td className={styles.tdTotal}>
                {findValueOfCoin(symbol, totalBalancesInUSD)}
              </td>
            </tr>
          ))}
        </table>
      </div>
    </>
  ) : null;
};
