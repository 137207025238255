import styles from "./BanksBelarusPage.module.css";
import { Header } from "../../components/header/Header";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useEffect, useRef, useState } from "react";
import { Navigate } from "react-router-dom";
import { AppPages, LinkButtons } from "../../types";
import {
  checkIsAuth,
  getDateAndTimeOfUpdate,
  getDateOfUpdate,
  sortBalancesArrayBankName,
  sortBalancesArrayByCurrency,
} from "../../helpers";
import { TabsMenu } from "../../components/tabs-menu/TabsMenu";
import {
  clearBanksState,
  fetchBanksBalancesStart,
  fetchBanksListStart,
} from "../../api/banks";
import { TableBanks } from "../../components/table/table-banks/TableBanks";
import { Spinner } from "../../components/spinner/Spinner";
import { fetchAllNbrbRatesStart } from "../../api/rates";

const LINKS_LIST = Object.values(LinkButtons);
const TABLE_CURR = ["USD", "EUR", "RUB", "BYN", "CNY", "CHF"];

type BanksBelarusPageProps = {};

export const BanksBelarusPage: React.FC<BanksBelarusPageProps> = () => {
  const [selectedLink, setSelectedLink] = useState(LinkButtons.BANKS_BEL);
  const summarizedBankBalanceArray = useAppSelector(
    (state) => state.banks.summarizedBalanceArray
  );
  const banksBalancesStatus = useAppSelector(
    (state) => state.banks.fetchBalanceStatus
  );
  let totalBalances = useAppSelector((state) => state.banks.totalValuesArray);
  const bankList = useAppSelector((state) => state.banks.bankList);
  const banksListStatus = useAppSelector(
    (state) => state.banks.fetchBankListStatus
  );
  const rates = useAppSelector((state) => state.rates.nbrbAllRatesList);
  let token = localStorage.getItem("accessToken");
  token = token ? JSON.parse(token) : "";
  const dispatch = useAppDispatch();
  const [isAuth, setIsAuth] = useState(true);
  const targetRef = useRef(null);

  useEffect(() => {
    dispatch(clearBanksState());
    dispatch(fetchBanksListStart({ accessToken: token }));
    dispatch(fetchAllNbrbRatesStart({ accessToken: token }));
    checkIsAuth().then((response) => setIsAuth(response));
  }, [dispatch, token]);

  useEffect(() => {
    if (bankList.length > 0) {
      for (let bank of bankList) {
        dispatch(
          fetchBanksBalancesStart({ bankId: bank.bankId, accessToken: token })
        );
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankList.length, dispatch, token]);

  if (banksBalancesStatus === "pending" || banksListStatus === "pending") {
    return <Spinner width={100} height={100} marginTop="200px"></Spinner>;
  }

  let bankAllSymbolsArray: string[] = [];
  let bankNamesArray: string[] = bankList.map((bank) => bank.bankName);
  let sortedData = [] as typeof summarizedBankBalanceArray;

  if (summarizedBankBalanceArray && summarizedBankBalanceArray.length) {
    sortedData = sortBalancesArrayBankName(
      bankNamesArray,
      summarizedBankBalanceArray,
      rates.result
    ); //сортировка полученных просуммированныхданных по имени банка в столбце
    //получение массива всех валют, в которых есть счета в банках

    sortedData.forEach((bank) => {
      if (bank.bankAcounts) {
        bank.bankAcounts.forEach((item) => {
          bankAllSymbolsArray.push(item.symbol);
        });
      }
    });
  }

  let totalBalancesWithConversionAllTable = sortBalancesArrayByCurrency(
    TABLE_CURR,
    totalBalances,
    rates.result
  );

  let totalBalancesInUSD = Math.trunc(Number(totalBalancesWithConversionAllTable.totalSumInUSD))
  .toString()
  .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 ")

  let totalBalancesInBYN = Math.trunc(Number(totalBalancesWithConversionAllTable.totalSumInBYN))
  .toString()
  .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 ")

  let timestampt = new Date().getTime();

  return isAuth ? (
    <>
      <Header selectedMenu="Treasury"></Header>
      <TabsMenu
        linkEnum={LinkButtons}
        links={LINKS_LIST}
        selectedLink={selectedLink}
        onLinkClick={() => setSelectedLink}
      />
      <section className={styles.main}>
        <div ref={targetRef} className={`container ${styles.mainContainer}`} id="container">
          <h2 className={styles.title}>Banks (Belarus)</h2>
          <div className={styles.totalFlex}>
            <div className={styles.totalItem}>
            <p className={styles.totalDescr}>
            Итого, BYN
          </p>
          <p className={styles.totalNum}>
          {totalBalancesInBYN}
          </p>
            </div>
            <div className={styles.totalItem}>
            <p className={styles.totalDescr}>
            Итого, USD
          </p>
          <p className={styles.totalNum}>
          {totalBalancesInUSD}
          </p>
            </div>
          </div>
          <TableBanks
            targetRef={targetRef}
            totalUSD={totalBalancesInUSD}
            totalBYN={totalBalancesInBYN}
            tableName={"Banks RB"}
            date={getDateOfUpdate(timestampt)}
            dateTime={getDateAndTimeOfUpdate(timestampt)}
            sortedData={sortedData}
            sortedBankSymbols={TABLE_CURR}
            totalBalances={
              sortBalancesArrayByCurrency(
                TABLE_CURR,
                totalBalances,
                rates.result
              ).sortedBankAccount
            }
            ratesArray={rates.result}
            exports={true}
            CSVsortedBankSymbols={TABLE_CURR}
            CSVtotalBalances={
              sortBalancesArrayByCurrency(
                TABLE_CURR,
                totalBalances,
                rates.result
              ).sortedBankAccount
            }
            token={token}
          ></TableBanks>
        </div>
      </section>
    </>
  ) : (
    <Navigate to={AppPages.LOGIN} />
  );
};
